import React from 'react';
import { Layout } from '../components/Layouts';
import ThemeSwitcher from '../components/ThemeSwitcher';
import { Box } from '../provider';

export default props => {
  return (
    <Layout header="Copywriter">
      <Box
        as="div"
        sx={{ fontSize: [4, 5, 6], mt: 1, mb: 3, textAlign: 'center' }}
      >
        Welcome, Rochelle{' '}
        <Box as="span" sx={{ fontSize: [1, 2, 3], verticalAlign: 'text-top' }}>
          *
        </Box>
      </Box>

      <Box as="div" sx={{ fontSize: [1, 2, 3] }}>
        <center>
          <Box
            as="img"
            sx={{ size: [100, 140, 180], borderRadius: '50%' }}
            src="/rochelle.jpg"
          />
        </center>
      </Box>
      {props.children}
      <Box
        as="p"
        sx={{ fontSize: [1, 2, 3], mb: 0, mt: ['150px', '200px', '300px'] }}
      >
        * Please note most samples contain conversational/informal language.
        Language would be modified to the target audience accordingly.
      </Box>
    </Layout>
  );
};
//
